import React from "react";
import DateView from "react-datepicker";
import { Field, FieldProps, ErrorMessage } from "formik";
import TextError from "./TextError";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  label: string;
  name: string;
  // You can add more props here as needed
}

const DatePicker: React.FC<DatePickerProps> = ({ label, name, ...rest }) => {
  return (
    <div>
      <label  className='form-label  text-gray-900 fs-6 mb-0' htmlFor={name}>{label}</label>
      <div className="position-relative mb-3">
      <Field name={name}>
        {({ form, field }: FieldProps) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <DateView
              id={name}
              {...field}
              {...rest}
              selected={value}
              onChange={(val) => setFieldValue(name, val)}
            />
          );
        }}
      </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default DatePicker;