import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { MenuItem } from './MenuItem';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { MegaMenu } from './MegaMenu';
import { getMenu } from '../../../../../app/modules/auth/core/_requests';
import { useAuth } from '../../../../../app/modules/auth';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../helpers';
// import { UserModel } from '../../../../../app/modules/auth';

type MenuPlacement = 'bottom-start' | 'right-start' | 'left-start';
type MenuTrigger = 'click' | '{default:"click", lg:"hover"}';

interface MenuItemType {
  title: string;
  to: string;
  submenus?: MenuItemType[];
  menuPlacement?: MenuPlacement;
  menuTrigger?: MenuTrigger;
  isMega?: boolean;
  type?:string;
}

const renderMenu = (menuItems: MenuItemType[]): React.ReactNode => {
  return menuItems.map((item) => {
    if (item.submenus && item.submenus.length > 0) {
      return (
        <MenuInnerWithSub
          key={item.to}
          title={item.title}
          to={item.to}
          hasArrow={true}
          menuPlacement={item.menuPlacement || 'right-start'}
          fontIcon='bi-layers'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          isMega={item.isMega || false}
         >
          {renderMenu(item.submenus)}
        </MenuInnerWithSub>
      );
    } else {
      return <MenuItem key={item.to}  type={item.type}  title={item.title} to={item.to}/>;
    }
  });
};

const createMenuWithMore = (menuArray: MenuItemType[]): MenuItemType[] => {
  const firstFive = menuArray.slice(0, 5);
  const remaining = menuArray.slice(5);

  if (remaining.length > 0) {
    const moreMenu: MenuItemType = {
      title: 'More',
      to: '#',
      submenus: remaining,
    };
    firstFive.push(moreMenu);
  }

  return firstFive;
};

export function MenuInner() {
  const [menuArray, setMenuArray] = useState<MenuItemType[]>([]);
  const intl = useIntl();
  const { currentUser } = useAuth();
  useEffect(() => {
    if (currentUser) {
      //console.log("Fetching menu data for email123", currentUser.email);
      fetchMenuData(currentUser.email);
    } else {
      //console.log("No current user or email available");
    }
  }, [currentUser]);

  const fetchMenuData = async (email: string) => {
    try {
      //console.log("Fetching menu data for email (inside fetch):", email);
      const menuData = await getMenu(email);
      //console.log("Fetched menu data:", menuData);
      const updatedMenu = createMenuWithMore(menuData);
      setMenuArray(updatedMenu);
        } catch (error) {
      console.error("Error fetching menu data: ", error);
    }
  };

  return (
    <>
    <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('media/logos/newPulseLogo.png')}
                        className='h-30px'
                      />
                </div>
                <div className='separator my-2' style={{width:"2px",height:"80%",backgroundColor:"lightgray",marginRight:"20px",opacity:"0.3"}}></div>
      {renderMenu(menuArray)}
    </>
  );
}
