import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { forgotPassword, resetPassword, verifyOTP } from "../core/_requests";

const ForgotPassword: React.FC = () => {
  const [step, setStep] = useState<"email" | "otp" | "reset">("email");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const navigate = useNavigate();

  const resetMessages = () => {
    setMessage("");
    setMessageColor("");
  };

  // Formik setup for email submission
  const emailFormik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      resetMessages();
      try {
        const response = await forgotPassword(values.email);
        setEmail(values.email);
        setMessage(response.data.message);
        setMessageColor("green");
        setTimeout(() => {
          resetMessages();
          setStep("otp");
        }, 2000);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setMessage(
            error.response?.data?.message ||
              "An error occurred. Please try again later."
          );
        } else {
          setMessage("An unexpected error occurred.");
        }
        setMessageColor("red");
      }
    },
  });

  // Formik setup for OTP submission
  const otpFormik = useFormik({
    initialValues: { otp: "" },
    validationSchema: Yup.object().shape({
      otp: Yup.string().required("OTP is required"),
    }),
    onSubmit: async (values) => {
      resetMessages();
      try {
        const response = await verifyOTP(email, values.otp);
        // axios.post(
        //   "http://localhost:6227/signup/verifyOtp",
        //   {
        //     email,
        //     otp: values.otp,
        //   }
        // );
        setMessage(response.data.message);
        setMessageColor("green");
        setTimeout(() => {
          resetMessages();
          setStep("reset");
        }, 2000);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setMessage(
            error.response?.data?.message ||
              "An error occurred. Please try again later."
          );
        } else {
          setMessage("An unexpected error occurred.");
        }
        setMessageColor("red");
      }
    },
  });

  // Formik setup for password reset
  const resetFormik = useFormik({
    initialValues: { newPassword: "", confirmPassword: "" },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      resetMessages();
      if (values.newPassword !== values.confirmPassword) {
        setMessage("Passwords do not match");
        setMessageColor("red");
        return;
      }

      //To do: need to move these server requests to _requests files.
      try {
        const response = await resetPassword(email, values.newPassword);

        // const response = await axios.post(
        //   "http://localhost:6227/signup/resetPassword",
        //   {
        //     email,
        //     newPassword: values.newPassword,
        //   }
        // );
        setMessage(response.data.message);
        setMessageColor("green");
        setTimeout(() => {
          resetMessages();
          navigate("/login");
        }, 2000);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setMessage(
            error.response?.data?.message || "Error resetting password"
          );
        } else {
          setMessage("An unexpected error occurred.");
        }
        setMessageColor("red");
      }
    },
  });

  return (
    <div className="forgot-password-container">
      <h1 className="text-gray-900 fw-bolder mb-3">Forgot Password?</h1>
      <div className="text-gray-500 fw-semibold fs-6">
        {step === "email" && "Enter your email to reset your password."}
        {step === "otp" && "Enter the OTP sent to your email."}
        {step === "reset" && "Enter a new password."}
      </div>

      {step === "email" && (
        <form onSubmit={emailFormik.handleSubmit} className="form w-100">
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder=""
              autoComplete="off"
              value={emailFormik.values.email}
              onChange={emailFormik.handleChange}
              onBlur={emailFormik.handleBlur}
              className={`form-control bg-transparent ${
                emailFormik.touched.email && emailFormik.errors.email
                  ? "is-invalid"
                  : ""
              }`}
            />
            {emailFormik.touched.email && emailFormik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{emailFormik.errors.email}</span>
                </div>
              </div>
            )}
            {message && <div style={{ color: messageColor }}>{message}</div>}
          </div>
          <div className="d-flex flex-wrap justify-content-center pb-lg-0">
            <button
              type="submit"
              className="btn btn-primary me-4"
              style={{ backgroundColor: "#78c0bd" }}
            >
              <span className="indicator-label">Submit</span>
            </button>
          </div>
        </form>
      )}

      {step === "otp" && (
        <form onSubmit={otpFormik.handleSubmit} className="form w-100">
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              OTP
            </label>
            <input
              type="text"
              name="otp"
              placeholder=""
              autoComplete="off"
              value={otpFormik.values.otp}
              onChange={otpFormik.handleChange}
              onBlur={otpFormik.handleBlur}
              className={`form-control bg-transparent ${
                otpFormik.touched.otp && otpFormik.errors.otp
                  ? "is-invalid"
                  : ""
              }`}
            />
            {otpFormik.touched.otp && otpFormik.errors.otp && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{otpFormik.errors.otp}</span>
                </div>
              </div>
            )}
            {message && <div style={{ color: messageColor }}>{message}</div>}
          </div>
          <div className="d-flex flex-wrap justify-content-center pb-lg-0">
            <button type="submit" className="btn btn-primary me-4">
              <span className="indicator-label">Verify OTP</span>
            </button>
          </div>
        </form>
      )}

      {step === "reset" && (
        <form onSubmit={resetFormik.handleSubmit} className="form w-100">
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              New Password
            </label>
            <input
              type="password"
              name="newPassword"
              placeholder=""
              autoComplete="off"
              value={resetFormik.values.newPassword}
              onChange={resetFormik.handleChange}
              onBlur={resetFormik.handleBlur}
              className={`form-control bg-transparent ${
                resetFormik.touched.newPassword &&
                resetFormik.errors.newPassword
                  ? "is-invalid"
                  : ""
              }`}
            />
            {resetFormik.touched.newPassword &&
              resetFormik.errors.newPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{resetFormik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            {message && <div style={{ color: messageColor }}>{message}</div>}
          </div>
          <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-gray-900 fs-6">
              Confirm Password
            </label>
            <input
              type="password"
              name="confirmPassword"
              placeholder=""
              autoComplete="off"
              value={resetFormik.values.confirmPassword}
              onChange={resetFormik.handleChange}
              onBlur={resetFormik.handleBlur}
              className={`form-control bg-transparent ${
                resetFormik.touched.confirmPassword &&
                resetFormik.errors.confirmPassword
                  ? "is-invalid"
                  : ""
              }`}
            />
            {resetFormik.touched.confirmPassword &&
              resetFormik.errors.confirmPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">
                      {resetFormik.errors.confirmPassword}
                    </span>
                  </div>
                </div>
              )}
            {message && <div style={{ color: messageColor }}>{message}</div>}
          </div>
          <div className="d-flex flex-wrap justify-content-center pb-lg-0">
            <button type="submit" className="btn btn-primary me-4">
              <span className="indicator-label">Reset Password</span>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
