import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = import.meta.env.VITE_APP_API_URL;
const API_URL_SIGNUP = `${import.meta.env.VITE_APP_API_URL}/signup`;
const API_URL_MENU = `${import.meta.env.VITE_APP_API_URL}/menu`;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL_SIGNUP}/verifyToken`;
export const LOGIN_URL = `${API_URL_SIGNUP}/login`;
export const FORGOT_PASSWORD = `${API_URL_SIGNUP}/forgotPassword`;
export const VERIFY_OTP = `${API_URL_SIGNUP}/verifyOtp`;
export const RESET_PASSWORD = `${API_URL_SIGNUP}/resetPassword`;


export const MENU_URL = `${API_URL_MENU}/userMenu`;

export const REGISTER_URL = `${API_URL_SIGNUP}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL_SIGNUP}/forgot_password`;

type MenuPlacement = 'bottom-start' | 'right-start' | 'left-start';
type MenuTrigger = 'click' | '{default:"click", lg:"hover"}';

interface MenuItemType {
  title: string;
  to: string;
  submenus?: MenuItemType[];
  menuPlacement?: MenuPlacement;
  menuTrigger?: MenuTrigger;
  isMega?: boolean;
}

// Server should return AuthModel
export async function login(email: string, password: string) {
  try {
    return await axios.post<AuthModel>(LOGIN_URL, {
      email,
      password,
    });
  } catch (error) {
    console.error("Login Error: ", error);
    throw error;
  }
}

export async function forgotPassword(email: string) {
  try {
    return await axios.post(
      FORGOT_PASSWORD,
      { email:email });
  } catch (error) {
    console.error("Forgot Password Error: ", error);
    throw error;
  }
}

export async function verifyOTP(email: string,OTP:string) {
  try {
    return await axios.post(
      VERIFY_OTP,
      {
        email,
        otp: OTP,
      });
  } catch (error) {
    console.error("Verify OTP Error: ", error);
    throw error;
  }
}

export async function resetPassword(email: string,newPassword:string) {
  try {
    return await axios.post(
      RESET_PASSWORD,{
        email,
        newPassword,
      });
  } catch (error) {
    console.error("Reset Password Error: ", error);
    throw error;
  }
}


export async function getMenu(email: string): Promise<MenuItemType[]> {
  try {
    const response = await axios.get<MenuItemType[]>(`${MENU_URL}?email=${email}`);
    //console.log("Menu data received from server:", response.data); 
    return response.data; // Return the data directly
  } catch (error) {
    console.error("Menu Error: ", error);
    throw error;
  }
}


// Server should return AuthModel
export async function register(
  firstName: string,
  lastName: string,
  mobileNumber: string,
  dateOfBirth: Date,
  chooseGender: string,
  maritalStatus: string,
  country: string,
  city: string,
  guardianName: string,
  guardianNumber: string,
  email: string,
  password: string,
  repeatPassword: string,
  profilePic: File | null,
  
) {
  const formData = new FormData();
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('mobileNumber', mobileNumber);
  formData.append('dateOfBirth', dateOfBirth.toISOString());
  formData.append('chooseGender', chooseGender);
  formData.append('maritalStatus', maritalStatus);
  formData.append('country', country);
  formData.append('city', city);
  formData.append('guardianName', guardianName); 
  formData.append('guardianNumber', guardianNumber); 
  formData.append('email', email);
  formData.append('password', password);
  formData.append('repeatPassword', repeatPassword);

  if (profilePic) {
    formData.append('profilePic', profilePic);
  }

  try {
    return await axios.post(REGISTER_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    console.error("Register Error: ", error);
    throw error;
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(email: string) {
  try {
    return await axios.post<{ result: boolean; }>(REQUEST_PASSWORD_URL, {
      email,
    });
  } catch (error) {
    console.error("Request Password Error: ", error);
    throw error;
  }
}

export async function getUserByToken(token: string) {
  try {
    //console.log('Sending token:', token); // Confirm the token value3
    return await axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
      api_token: token,
    });
  } catch (error) {
    console.error("Get User By Token Error: ", error);
    throw error;
  }
}
