import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import TextError from './TextError';
import clsx from 'clsx';

interface InputProps {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
  className?: string;
  [key: string]: any;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  type = 'text',
  placeholder,
  autoComplete,
  className,
  ...rest
}) => {
  const [field, meta] = useField(name);

  const renderPasswordMeter = () => {
    if (type === 'Password') {
      return (
        <>
          <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div className='fv-row mb-8'>
      <div className='mb-1'>
        <label className='form-label  text-gray-900 fs-6 mb-0' htmlFor={name}>
          {label}
        </label>
        <div className='position-relative mb-3'>
          <Field
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            autoComplete={autoComplete}
            className={clsx(
              className,
              {
                'is-invalid': meta.touched && meta.error,
              },
              {
                'is-valid': meta.touched && !meta.error,
              }
            )}
            {...rest}
          />
          <ErrorMessage name={name} component={TextError} />
        </div>
        {renderPasswordMeter()}
      </div>
    </div>
  );
};

export default Input;
