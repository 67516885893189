import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link} from 'react-router-dom';
import { ErrorMessage, Formik } from 'formik';
import { getUserByToken, register } from '../core/_requests';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../core/Auth';
import FormikControl from '../../../controls/FormikControl';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';
import TextError from '../../../controls/TextError';

export function Registration() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
 
  //{ key: "India", value: "IN" },
  const countryOptions = [
    { key: "Select country", value: "" },   
    { key: "Afghanistan" },
    { key: "Åland Islands"},
    { key: "Albania"},
    { key: "Algeria"},                   
    { key: "American Samoa"},
    { key: "Andorra"},
    { key: "Angola"},
    { key: "Anguilla"},
    { key: "Antarctica"},
    { key: "Antigua and Barbuda"},
    { key: "Argentina"},
    { key: "Armenia"},
    { key: "Aruba"},
    { key: "Australia"},
    { key: "Austria"},
    { key: "Azerbaijan"},
    { key: "Bahamas"},
    { key: "Bahrain"},
    { key: "Bangladesh"},
    { key: "Barbados"},
    { key: "Belarus"},
    { key: "Belgium"},
    { key: "Belize"},
    { key: "Benin"},
    { key: "Bermuda"},
    { key: "Bhutan"},
    { key: "Bolivia"},
    { key: "Bosnia and Herzegovina"},
    { key: "Botswana"},
    { key: "Bouvet Island"},
    { key: "Brazil"},
    { key: "British Indian Ocean Territory"},
    { key: "Brunei Darussalam"},
    { key: "Bulgaria"},
    { key: "Burkina Faso"},
    { key: "Burundi"},
    { key: "Cabo Verde"},
    { key: "Cambodia"},
    { key: "Cameroon"},
    { key: "Canada"},
    { key: "Cayman Islands"},
    { key: "Central African Republic"},
    { key: "Chad"},
    { key: "Chile"},
    { key: "China"},
    { key: "Christmas Island"},
    { key: "Cocos (Keeling) Islands"},
    { key: "Colombia"},
    { key: "Comoros"},
    { key: "Congo"},
    { key: "Congo, Democratic Republic of the"},
    { key: "Cook Islands"},
    { key: "Costa Rica"},
    { key: "Côte d'Ivoire"},
    { key: "Croatia"},
    { key: "Cuba"},
    { key: "Cyprus"},
    { key: "Czech Republic"},
    { key: "Denmark"},
    { key: "Djibouti"},
    { key: "Dominica"},
    { key: "Dominican Republic"},
    { key: "Ecuador"},
    { key: "Egypt"},
    { key: "El Salvador"},
    { key: "Equatorial Guinea"},
    { key: "Eritrea"},
    { key: "Estonia"},
    { key: "Eswatini"},
    { key: "Ethiopia"},
    { key: "Falkland Islands (Malvinas)"},
    { key: "Faroe Islands"},
    { key: "Fiji"},
    { key: "Finland"},
    { key: "France"},
    { key: "French Guiana"},
    { key: "French Polynesia"},
    { key: "French Southern Territories"},
    { key: "Gabon"},
    { key: "Gambia"},
    { key: "Georgia"},
    { key: "Germany"},
    { key: "Ghana"},
    { key: "Gibraltar"},
    { key: "Greece"},
    { key: "Greenland"},
    { key: "Grenada"},
    { key: "Guadeloupe"},
    { key: "Guam"},
    { key: "Guatemala"},
    { key: "Guernsey"},
    { key: "Guinea"},
    { key: "Guinea-Bissau"},
    { key: "Guyana"},
    { key: "Haiti"},
    { key: "Heard Island and McDonald Islands"},
    { key: "Holy See"},
    { key: "Honduras"},
    { key: "Hong Kong"},
    { key: "Hungary"},
    { key: "Iceland"},
    { key: "India"},
    { key: "Indonesia"},
    { key: "Iran"},
    { key: "Iraq"},
    { key: "Ireland"},
    { key: "Isle of Man"},
    { key: "Israel"},
    { key: "Italy"},
    { key: "Jamaica"},
    { key: "Japan"},
    { key: "Jersey"},
    { key: "Jordan"},
    { key: "Kazakhstan"},
    { key: "Kenya"},
    { key: "Kiribati"},
    { key: "North Korea"},
    { key: "South Korea"},
    { key: "Kuwait"},
    { key: "Kyrgyzstan"},
    { key: "Laos"},
    { key: "Latvia"},
    { key: "Lebanon"},
    { key: "Lesotho"},
    { key: "Liberia"},
    { key: "Libya"},
    { key: "Liechtenstein"},
    { key: "Lithuania"},
    { key: "Luxembourg"},
    { key: "Macao"},
    { key: "Madagascar"},
    { key: "Malawi"},
    { key: "Malaysia"},
    { key: "Maldives"},
    { key: "Mali"},
    { key: "Malta"},
    { key: "Marshall Islands"},
    { key: "Martinique"},
    { key: "Mauritania"},
    { key: "Mauritius"},
    { key: "Mayotte"},
    { key: "Mexico"},
    { key: "Micronesia"},
    { key: "Moldova"},
    { key: "Monaco"},
    { key: "Mongolia"},
    { key: "Montenegro"},
    { key: "Montserrat"},
    { key: "Morocco"},
    { key: "Mozambique"},
    { key: "Myanmar"},
    { key: "Namibia"},
    { key: "Nauru"},
    { key: "Nepal"},
    { key: "Netherlands"},
    { key: "New Caledonia"},
    { key: "New Zealand"},
    { key: "Nicaragua"},
    { key: "Niger"},
    { key: "Nigeria"},
    { key: "Niue"},
    { key: "Norfolk Island"},
    { key: "North Macedonia"},
    { key: "Northern Mariana Islands"},
    { key: "Norway"},
    { key: "Oman"},
    { key: "Pakistan"},
    { key: "Palau"},
    { key: "Palestine"},
    { key: "Panama"},
    { key: "Papua New Guinea"},
    { key: "Paraguay"},
    { key: "Peru"},
    { key: "Philippines"},
    { key: "Pitcairn"},
    { key: "Poland"},
    { key: "Portugal"},
    { key: "Puerto Rico"},
    { key: "Qatar"},
    { key: "Réunion"},
    { key: "Romania"},
    { key: "Russia"},
    { key: "Rwanda"},
    { key: "Saint Barthélemy"},
    { key: "Saint Helena, Ascension and Tristan da Cunha"},
    { key: "Saint Kitts and Nevis"},
    { key: "Saint Lucia"},
    { key: "Saint Martin (French part)"},
    { key: "Saint Pierre and Miquelon"},
    { key: "Saint Vincent and the Grenadines"},
    { key: "Samoa"},
    { key: "San Marino"},
    { key: "Sao Tome and Principe"},
    { key: "Saudi Arabia"},
    { key: "Senegal"},
    { key: "Serbia"},
    { key: "Seychelles"},
    { key: "Sierra Leone"},
    { key: "Singapore"},
    { key: "Sint Maarten (Dutch part)"},
    { key: "Slovakia"},
    { key: "Slovenia"},
    { key: "Solomon Islands"},
    { key: "Somalia"},
    { key: "South Africa"},
    { key: "South Georgia and the South Sandwich Islands"},
    { key: "South Sudan"},
    { key: "Spain"},
    { key: "Sri Lanka"},
    { key: "Sudan"},
    { key: "Suriname"},
    { key: "Svalbard and Jan Mayen"},
    { key: "Sweden"},
    { key: "Switzerland"},
    { key: "Syria"},
    { key: "Taiwan"},
    { key: "Tajikistan"},
    { key: "Tanzania"},
    { key: "Thailand"},
    { key: "Timor-Leste"},
    { key: "Togo"},
    { key: "Tokelau"},
    { key: "Tonga"},
    { key: "Trinidad and Tobago"},
    { key: "Tunisia"},
    { key: "Turkey"},
    { key: "Turkmenistan"},
    { key: "Turks and Caicos Islands"},
    { key: "Tuvalu"},
    { key: "Uganda"},
    { key: "Ukraine"},
    { key: "United Arab Emirates"},
    { key: "United Kingdom"},
    { key: "United States"},
    { key: "United States Minor Outlying Islands"},
    { key: "Uruguay"},
    { key: "Uzbekistan"},
    { key: "Vanuatu"},
    { key: "Venezuela"},
    { key: "Vietnam"},
    { key: "British Virgin Islands"},
    { key: "U.S. Virgin Islands"},
    { key: "Wallis and Futuna"},
    { key: "Western Sahara"},
    { key: "Yemen"},
    { key: "Zambia"},
    { key: "Zimbabwe"},
  ];
  

  const genderOptions = [
    { key: 'Male', value: 'male' },
    { key: 'Female', value: 'female' },
  ];

  const maritalStatusOptions = [
    { key: 'Single', value: 'single' },
    { key: 'Married', value: 'married' },
  ];

  // Regex for the validations of different fields used in Signup form.
  const nameRegex = /^[a-zA-Z\- ']+$/;
  const mobileNumberRegex = /^[0-9]{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const registrationSchema = Yup.object().shape({
      firstName: Yup.string()
        .matches(nameRegex, 'Invalid firstname')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('First name is required'),
      lastName: Yup.string()
        .matches(nameRegex, 'Invalid lastname')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Last name is required'),
      chooseGender: Yup.string()
        .oneOf(['male', 'female'], 'Invalid gender')
        .required('Required!'),
      maritalStatus: Yup.string()
        .oneOf(['single', 'married'], 'Invalid marital status')
        .required('Required!'),
      city: Yup.string()
        .matches(nameRegex, 'Invalid city name')
        .required('Required!'),
      country: Yup.string().required('Please select a country'),
      guardianName: Yup.string()
        .matches(nameRegex, 'Invalid guardian name')
        .required('Guardian Name is required'),
      guardianNumber: Yup.string()
        .matches(mobileNumberRegex, 'Invalid mobile number')
        .required('Mobile Number is required'),
      mobileNumber: Yup.string()
        .matches(mobileNumberRegex, 'Invalid mobile number')
        .required('Mobile Number is required'),
      dateOfBirth: Yup.date().required('Date of Birth is required'),
      email: Yup.string()
        .matches(emailRegex, 'Invalid email format')
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
      password: Yup.string()
        // .matches(passwordRegex, 'Please enter a valid password')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
      repeatPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
      profilePic: Yup.mixed().required('Profile pic cannot be null'),
      acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
    });

    const initialValues = {
      firstName: '',
      lastName: '',
      chooseGender: '',
      maritalStatus: '',
      city: '',
      country: '',
      guardianName: '',
      guardianNumber: '',
      mobileNumber: '',
      dateOfBirth: '',
      email: '',
      password: '',
      repeatPassword: '',
      profilePic: null,
      acceptTerms: '',
    };

    

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  const [previewImage, setPreviewImage] = useState<null | string>(
    toAbsoluteUrl('media/auth/images/no-pic3.png')
  );

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ): void => {
    const selectedImage = event.currentTarget.files?.[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(selectedImage);
      setFieldValue('profilePic', selectedImage);
    } else {
      setPreviewImage(null);
      setFieldValue('profilePic', null);
    }
  };

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={registrationSchema}
    onSubmit={async (values, { setStatus, setSubmitting }) => {
      //alert("User registered successfully")

      setLoading(true);
      try {
        const { data: auth } = await register(
          values.firstName,
          values.lastName,
          values.mobileNumber,
          new Date(values.dateOfBirth),
          values.chooseGender,
          values.maritalStatus,
          values.country,
          values.city,
          values.guardianName,
          values.guardianNumber,
          values.email,
          values.password,
          values.repeatPassword,
          values.profilePic,
        );
        //console.log("auth");
        //console.log(auth);
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
        //console.log("onSubmit without error");
        //console.log(user);
      } catch (error:any) {
        //console.log("error");
        //console.error(error.response.data.message);
        console.error(error)
        saveAuth(undefined)
        setStatus(error.response.data.message);
        setSubmitting(false)
        setLoading(false)
        // console.error('Registration error', error);
        // saveAuth(undefined);
        // setStatus('The registration details are incorrect');
      }
      //  finally {
      //   setSubmitting(false);
      //   setLoading(false);
      // }
    }}
  >
      {({
        setFieldValue,
        isSubmitting,
        handleSubmit,
        touched,
        errors,
        status,
      }) => (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={handleSubmit}
        >

<div className='text-center mb-20'>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('media/logos/newPulseLogo.png')}
                        className='h-50px'
                      />
                      <div className='text-gray-500 fw-semibold fs-6'>For tracking progress</div>
                </div>

          <div className='text-center mb-11'>
            <h1 className='text-gray-900 fw-bolder mb-3'>Sign Up</h1>
            {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
          </div>

          {/* <div className='row g-3 mb-9'>
            <div className='col-md-6'>
              <a
                href='#'
                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('media/svg/brand-logos/google-icon.svg')}
                  className='h-15px me-3'
                />
                Sign in with Google
              </a>
            </div>
            <div className='col-md-6'>
              <a
                href='#'
                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('media/svg/brand-logos/apple-black.svg')}
                  className='theme-light-show h-15px me-3'
                />
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('media/svg/brand-logos/apple-black-dark.svg')}
                  className='theme-dark-show h-15px me-3'
                />
                Sign in with Apple
              </a>
            </div>
          </div> */}

          {/* <div className='separator separator-content my-14'>
            <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
          </div> */}

          {status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{status}</div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='First name'
              name='firstName'
              type='text'
              placeholder='First name'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.firstName && errors.firstName },
                { 'is-valid': touched.firstName && !errors.firstName }
              )}
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='Last name'
              name='lastName'
              type='text'
              placeholder='Last name'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.lastName && errors.lastName },
                { 'is-valid': touched.lastName && !errors.lastName }
              )}
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='Mobile Number'
              name='mobileNumber'
              type='text'
              placeholder='Mobile Number'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.mobileNumber && errors.mobileNumber },
                { 'is-valid': touched.mobileNumber && !errors.mobileNumber }
              )}
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='Date of Birth'
              name='dateOfBirth'
              type='date'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.dateOfBirth && errors.dateOfBirth },
                { 'is-valid': touched.dateOfBirth && !errors.dateOfBirth }
              )}
              autoComplete='off'
            />
          </div>


          <div className='fv-row mb-8'>
          <FormikControl
                control="radio"
                name="chooseGender"
                label="Gender:"
                options={genderOptions}
                className="formRadio"
              />
              </div>

              <div className='fv-row mb-8'>
               <FormikControl
                control='radio'
                type="radio"
                label='Marital Status:'
                name='maritalStatus'
                className="formRadio"
                options={maritalStatusOptions}
               
                autoComplete='off'
              />
              </div>


          <div className='fv-row mb-8'>
            <FormikControl
              control='select'
              label='Country'
              name='country'
              options={countryOptions}
              placeholder='Select country'
              className={clsx(
                'form-select bg-transparent',
                { 'is-invalid': touched.country && errors.country },
                { 'is-valid': touched.country && !errors.country }
              )}
            />
          </div>

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='City'
              name='city'
              type='text'
              placeholder='City'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.city && errors.city },
                { 'is-valid': touched.city && !errors.city }
              )}
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='Guardian Name'
              name='guardianName'
              type='text'
              placeholder='Guardian Name'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.guardianName && errors.guardianName },
                { 'is-valid': touched.guardianName && !errors.guardianName }
              )}
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='Guardian Number'
              name='guardianNumber'
              type='text'
              placeholder='Guardian Number'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.guardianNumber && errors.guardianNumber },
                { 'is-valid': touched.guardianNumber && !errors.guardianNumber }
              )}
              autoComplete='off'
            />
          </div>

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='Email'
              name='email'
              type='email'
              placeholder='Email'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.email && errors.email },
                { 'is-valid': touched.email && !errors.email }
              )}
              autoComplete='off'
            />
          </div>
          <div className='fv-row mb-8' data-kt-password-meter='true'>
            <div className='mb-1'>
              <FormikControl
                control='input'
                label='Password'
                name='password'
                type='password'
                placeholder='Password'
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': touched.password && errors.password },
                  { 'is-valid': touched.password && !errors.password }
                )}
                autoComplete='off'
              />
            </div>

            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
          </div>

          <div className='fv-row mb-8'>
            <FormikControl
              control='input'
              label='Confirm Password'
              name='repeatPassword'
              type='password'
              placeholder='Password confirmation'
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.repeatPassword && errors.repeatPassword },
                { 'is-valid': touched.repeatPassword && !errors.repeatPassword }
              )}
              autoComplete='off'
            />
          </div>


          <div className='fv-row mb-8'>
            <div className='mb-1'>
              <label className='form-label fw-semibold text-gray-900 fs-6'>Profile Picture</label>
             
                <br/>
              <input
                    type='file'
                    name='profilePic'
                    className='text-muted'
                    accept='image/*'
                    onChange={(event) => handleImageChange(event, setFieldValue)}
                  />
                  <br/>
                  <br/>
                <div className='image-input image-input-outline' style={{ backgroundImage: `url('${toAbsoluteUrl('media/avatars/blank.png')}')` }}>              {previewImage && <img src={previewImage} alt="Profile Preview" className='image-input-wrapper w-125px h-125px' 
                   style={{ width: "125px", height: "125px", borderRadius:7.6,position:"relative",left:140
                     
                   }}/>}
                </div>
                <div className='mt-4'>
                 
                  <ErrorMessage name='profilePic' component={TextError} />
                
              </div>
            </div>
          </div>
        

          <br/>
           <br/>
          <FormikControl
            name="acceptTerms"
            label={(
              <>
                I Accept the{' '}
                <a
                  href='https://docs.google.com/document/d/1UNc8jOJtjQJttCxogPfFQcXFl6gNm8iuOwiRfbkmXcw/edit'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='ms-1 link-primary'
                >
                  Terms and Conditions
                </a>
                .
              </>
            )}
            formik={Formik} control={'checkbox'} />
            <br/>


          <div className='d-grid mb-10'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn'
              style={{backgroundColor:"#78c0bd"}}
              disabled={isSubmitting}
            >
              {!isSubmitting && <span className='indicator-label text-light'>Submit</span>}
              {isSubmitting && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <Link to='/auth/login'>
              <button type='button' id='kt_login_signup_form_cancel' className='btn btn-lg btn-light-primary w-100 mb-5'>
                Cancel
              </button>
            </Link>

          <div className='text-gray-500 text-center fw-semibold fs-6'>
            Already have an Account?
            <Link to='/auth/login' className='link-primary fw-semibold'>
              Sign in
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default Registration;
