import React from 'react';

interface TextErrorProps {
  children?: React.ReactNode;
}

const TextError: React.FC<TextErrorProps> = ({ children }) => {
  return <div className="error" style={{color:"red"}}>{children}</div>;
};

export default TextError;
