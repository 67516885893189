import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

interface CheckboxProps {
  label: string;
  name: string;
  [key: string]: any; // Other props
}

const Checkbox: React.FC<CheckboxProps> = ({ label, name, ...rest }) => {
  return (
    <div>
      <Field name={name}>
        {({ field }: { field: any }) => (
          <div className="checkbox-field">
            <input className='form-check-input'
              type="checkbox"
              id={name}
              {...field}
              {...rest}
              checked={field.value}
            />
            <label className='form-check form-check-inline'  htmlFor='kt_login_toc_agree'>
              {label}
            </label>
          </div>
        )}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Checkbox;
