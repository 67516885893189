import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

interface RadioButtonsProps {
  label: string;
  name: string;
  options: { key: string; value: string }[];
}

const RadioButtons: React.FC<RadioButtonsProps> = ({ label, name, options }) => {
  return (
    <div className='formRadio'>
      <label className='form-label  text-gray-900 fs-6 mb-0'>{label}</label>
      <div role="group" aria-labelledby={name}>
        {options.map(option => (
          <label style={{padding:10,marginLeft:40}} key={option.value} className="radio-label fw-bolder fs-6">
            <Field className="form-check-input" type="radio" name={name} value={option.value} />
            &nbsp;&nbsp;
            {option.key}
          </label>
        ))}
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default RadioButtons;
