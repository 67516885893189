
import {useEffect, useState} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {

  const [isLargeScreen,setIsLargeScreen   ] = useState(window.innerWidth >= 992)
  useEffect (()=>{
    const handleResize = ()=>{
      setIsLargeScreen(window.innerWidth >= 992)
    }
    window.addEventListener('resize',handleResize)
    handleResize()
    return ()=> window.removeEventListener('resize',handleResize)
  },[])

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-justify-content-start-fluid w-lg-50 p-10 order-2 order-lg-1'>
      {/* justify-content-lg-start align-items-lg-start */}
      {/* d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 */}
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='https://docs.google.com/document/d/1UNc8jOJtjQJttCxogPfFQcXFl6gNm8iuOwiRfbkmXcw/edit'
                   className='px-5' target='_blank'>
              Terms and Conditions
            </a>

            {/* <a href='#' className='px-5' target='_blank'>
              Plans
            </a> */}

            <a href='https://www.brninfotech.com' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 h-lg-100 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('media/misc/auth-bglayout.jpg')})`,
        ...(isLargeScreen ? {position: 'fixed',right:0} : {})
      }}


      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100' 
        style={{
          marginTop: 70,
        }}
        >
          {/* begin::Logo */}
          {/* <Link to='/' className='mb-2'>
            <img alt='Logo' src={toAbsoluteUrl('media/logos/newPulseLogo.png')} className='h-40px w-200px' />
          </Link> */}
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-325px mb-5'
            src={toAbsoluteUrl('media/misc/auth-gif-layout.gif')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-dark fs-1qx fw-bolder text-center mb-4'>
            &lt; <a href='#' className='opacity-75-hover  fw-bold me-1' 
             style={{
              color:'#e54e48'
            }}>
            B
            </a>elieve-<a href='#' className='opacity-75-hover fw-bold me-1' 
            style={{
              color:'#909090'
            }}>
            R
            </a>ise-<a href='#' className='opacity-75-hover  fw-bold me-1'
            style={{
              color:'#e54e48'
            }}>
            N
            </a>everGiveup &gt;
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-dark fs-base text-center pb-10'>
            {' '}
            <a href='#' className='opacity-75-hover fw-bold me-1'
            style={{
              color:'#e54e48'
            }}>
            BRN,  
            </a>
            invites you to seize new opportunities and elevate your career, with each task contributing to excellence and growth. By tracking progress, refining actions, and celebrating achievements,
            
            
            <br /> <a href='#' className='opacity-75-hover fw-bold me-1'
            style={{
              color:'#e54e48'
            }}>
            Success 
            </a>
            becomes the result of consistent daily efforts.
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
