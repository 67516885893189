import { FC } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { checkIsActive, KTIcon } from '../../../../helpers'

type Props = {
  to: string
  type?: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
}
const MenuItem: FC<Props> = ({ to, type, title, icon, fontIcon, hasArrow = false, hasBullet = false }) => {
  const { pathname } = useLocation()
// Check if the type is '<a>' to use anchor tag
  const isAnchorTag = type === '<a>'
  return (
    <div className='menu-item me-lg-1'>
      {type == "a" ? (
        // Use anchor tag if the type is '<a>'
        <a
          href={to}
          className={clsx('menu-link py-3', {
            'active menu-here': checkIsActive(pathname, to),
          })}
          target='_blank' // Open in a new tab (for external links)
          rel='noopener noreferrer' // Security feature for opening in a new tab
        >
           {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && (
            <span className='menu-icon'>
              <KTIcon iconName={icon} className='fs-2' />
            </span>
          )}
           {fontIcon && (
            <span className='menu-icon'>
              <i className={clsx('bi fs-3', fontIcon)}></i>
            </span>
          )}

          <span className='menu-title'>{title}</span>

          {hasArrow && <span className='menu-arrow'></span>}
          
        </a>
      ) : (
        // Use Link for internal routing
        <Link
          className={clsx('menu-link py-3', {
            'active menu-here': checkIsActive(pathname, to),
          })}
          to={to}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}

          {icon && (
            <span className='menu-icon'>
              <KTIcon iconName={icon} className='fs-2' />
            </span>
          )}

          {fontIcon && (
            <span className='menu-icon'>
              <i className={clsx('bi fs-3', fontIcon)}></i>
            </span>
          )}

          <span className='menu-title'>{title}</span>

          {hasArrow && <span className='menu-arrow'></span>}
        </Link>
      )}
    </div>
  )
}

export { MenuItem }
