import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

interface TextareaProps {
  label: string;
  name: string;
  rows?: number;
  cols?: number;
  [key: string]: any; // Other props
}

const Textarea: React.FC<TextareaProps> = ({ label, name, rows = 4, cols = 50, ...rest }) => {
  return (
    <div>
      <label className='form-label text-gray-900 fs-6 mb-0' htmlFor={name}>{label}</label>
      <Field
        as="textarea"
        id={name}
        name={name}
        rows={rows}
        cols={cols}
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Textarea;
