/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {BASE_URL} = import.meta.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  //console.log("AppRoutes");
  //console.log(currentUser);
  //console.log(BASE_URL);

  // Normalize BASE_URL for consistency
const normalizedBaseURL = BASE_URL === './' ? '/' : BASE_URL;
//console.log(normalizedBaseURL);

  return (
    <BrowserRouter basename={normalizedBaseURL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />



          {currentUser ? (
  <>
    {currentUser.role === 'admin' ? (
      <>
        <Route path='/*' element={<PrivateRoutes />} />
        <Route index element={<Navigate to='/batches' />} />
      </>
    ) : currentUser.role === 'student' ? (
      <>
        <Route path='/*' element={<PrivateRoutes />} />
        <Route index element={<Navigate to='/tasks' />} />
      </>
    ) : (
      // Handle case where role is neither admin nor student, if needed
      <Route path='*' element={<Navigate to='/not-authorized' />} />
    )}
  </>
) : (
  <>
    <Route path='auth/*' element={<AuthPage />} />
    <Route path='*' element={<Navigate to='/auth' />} />
  </>
)}


          {/* {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/batches' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )} */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
