import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

interface SelectProps {
  label: string;
  name: string;
  options: { key: string, value: string }[];
  [key: string]: any; // Other props
}

const Select: React.FC<SelectProps> = ({ label, name, options, ...rest }) => {
  return (
    <div>
      <label className='form-label text-gray-900 fs-6 mb-0' htmlFor={name}>{label}</label>
     
      <Field  className='form-control bg-transparent form-select 
      form-select-lg' as="select" id={name} name={name} {...rest}>
        {options.map(option => (
          <option  key={option.value} value={option.value}>
            {option.key}
          </option>
        ))}
      </Field>
    
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Select;
