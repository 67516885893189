import React from 'react';
import Input from './Input';
import RadioButtons from './RadioButtons';
import Checkbox from './Checkbox';
import Select from './Select';
import Textarea from './Textarea';
import TextError from './TextError';
import DatePicker from './DatePicker';


interface FormikControlProps {
  control: 'input' | 'radio' | 'checkbox' | 'select' | 'date' | 'textarea';
  name: string;
  label: string | React.ReactNode;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
  className?: string;
  [key: string]: any;
}

const FormikControl: React.FC<FormikControlProps> = (props) => {
  
  const { control, error, touched, ...rest } = props;

  let Component: React.FC<any>;
  switch (control) {
    case 'input':
      Component = Input;
      break;
    case 'radio':
      Component = RadioButtons;
      break;
    case 'checkbox':
      Component = Checkbox;
      break;
    case 'select':
      Component = Select;
      break;
    case 'date':
      Component = DatePicker;
      break;
    case 'textarea':
      Component = Textarea;
      break;
    default:
      return null;
  }

  return (
    <div>
      <Component {...rest} />
      {touched && error && <TextError>{error}</TextError>}
    </div>
  );
};

export default FormikControl;
