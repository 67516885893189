import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";

// import FeedbackForm from '../modules/accounts/components/settings/cards/FeedbackForm'

import { ProfileHeader } from "../modules/profile/ProfileHeader";
import { useAuth } from "../modules/auth";


// import BatchesPage from '../modules/apps/batch-management/BatchesPage'
// import { UserEditModalForm } from '../modules/apps/batch-management/users-list/user-edit-modal/UserEditModalForm'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const BatchPage = lazy(
    () => import("../modules/apps/batch-management/batch-overview/BatchPage")
  );
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const TasksPage = lazy(
    () => import("../modules/apps/task-submit-management/TasksPage")
  );
  const BatchesPage = lazy(
    () => import("../modules/apps/batch-management/BatchesPage")
  );
  // const BatchesPage = lazy(() => import('../modules/apps/batch-management/BatchesPage'))
  // const UserManagementUsersPage = lazy(
  //   () => import("../modules/apps/user-management/UserManagementUsersPage")
  // );

  const { currentUser } = useAuth();

  //console.log("PrivateRoutes");
  //console.log(currentUser);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to batches after success login/registartion */}

        {currentUser?.role == "admin" ? (
          <Route path="auth/*" element={<Navigate to="/batches" />} />
        ) : (
          <Route path="auth/*" element={<Navigate to="/tasks" />} />
        )}

        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* <Route path='feedback' element={<FeedbackForm/>} /> */}

        <Route
          path="tasks/*"
          element={
            <SuspensedView>
              <TasksPage />
            </SuspensedView>
          }
        />

        <Route
          path="batches/*"
          element={
            <SuspensedView>
              <BatchesPage />
            </SuspensedView>
          }
        ></Route>

        <Route
          path={`:batchCode/*`}
          element={
            <SuspensedView>
              <BatchPage />
            </SuspensedView>
          }
        />
        

        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}

        <Route path="menu-test" element={<MenuTestPage />} />
        {/* <Route path='batches/*' element={
          <SuspensedView>
            <ProfileHeader  />
          </SuspensedView>
        }/> */}

        {/* <Route path='/crafted/pages/profile/:batchCode/*' element={<ProfilePage />} /> */}

        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/:batchCode/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <BatchPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/submit-management/*'
          element={
            <SuspensedView>
              <SubmitManagementUsersPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UserManagementUsersPage />
            </SuspensedView>
          }
        /> */}

        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
