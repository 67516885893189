import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import FormikControl from "../../../controls/FormikControl";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const loginSchema = Yup.object({
  email: Yup.string()
    .matches(emailRegex, "Invalid email format")
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        setLoading(true);
        try {
          const { data: auth } = await login(values.email, values.password);
          saveAuth(auth);
          const { data: user } = await getUserByToken(auth.api_token);
          setCurrentUser(user);
          console.log("window.electron:", window.electron);
          console.log(user);
          // Send the login success message to the main process
          // if (window.electron && window.electron.send) {
          //   window.electron.send("login-success", user);
          // } else {
          //   console.error(
          //     "Electron API is not available or send method is undefined!"
          //   );
          // }
        } catch (error: any) {
          console.error(error);
          saveAuth(undefined);
          // setStatus('The login details are incorrect')
          setStatus(error.response.data.message);
          setSubmitting(false);
          setLoading(false);
        }
      }}
    >
      {({ handleSubmit, touched, errors, status }) => (
        <form
          className="form w-100"
          onSubmit={handleSubmit}
          noValidate
          id="kt_login_signin_form"
        >
          <div className="text-center mb-20">
            <img
              alt="Logo"
              src={toAbsoluteUrl("media/logos/newPulseLogo.png")}
              className="h-50px"
            />
            <div className="text-gray-500 fw-semibold fs-6">
              For tracking progress
            </div>
          </div>
          <div className="text-center mb-11">
            <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
            {/* <div className='text-gray-500 fw-semibold fs-6'>For tracking progress</div> */}
          </div>

          {/* <div className='row g-3 mb-9'>
            <div className='col-md-6'>
              <a
                href='#'
                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('media/svg/brand-logos/google-icon.svg')}
                  className='h-15px me-3'
                />
                Sign in with Google
              </a>
            </div>
            <div className='col-md-6'>
              <a
                href='#'
                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('media/svg/brand-logos/apple-black.svg')}
                  className='theme-light-show h-15px me-3'
                />
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('media/svg/brand-logos/apple-black-dark.svg')}
                  className='theme-dark-show h-15px me-3'
                />
                Sign in with Apple
              </a>
            </div>
          </div> */}

          {/* <div className='separator separator-content my-14'>
            <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
          </div> */}

          {status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{status}</div>
            </div>
          )}

          <div className="fv-row mb-8">
            <FormikControl
              control="input"
              label="Email"
              name="email"
              type="email"
              placeholder="Email"
              className={clsx(
                "form-control bg-transparent",
                { "is-invalid": touched.email && errors.email },
                { "is-valid": touched.email && !errors.email }
              )}
              autoComplete="off"
            />
          </div>

          <div className="fv-row mb-3">
            <FormikControl
              control="input"
              label="Password"
              name="password"
              type="password"
              placeholder="Password"
              className={clsx(
                "form-control bg-transparent",
                { "is-invalid": touched.password && errors.password },
                { "is-valid": touched.password && !errors.password }
              )}
              autoComplete="off"
            />
          </div>

          <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
            <div />
            <Link to="/auth/forgot-password" className="link-primary">
              Forgot Password ?
            </Link>
          </div>

          <div className="d-grid mb-10">
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn"
              style={{ backgroundColor: "#78c0bd" }}
            >
              {!loading && (
                <span className="indicator-label text-light">Continue</span>
              )}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>

          <div className="text-gray-500 text-center fw-semibold fs-6">
            Not a Member yet?{" "}
            <Link to="/auth/registration" className="link-primary">
              Sign up
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
}
